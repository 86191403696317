.root {
  margin-top: 64px;
  padding: 32px;
}

.loginForm {
  display: grid;
  gap: 16px;
}

.eventGen {
  padding: 32px;
  display: grid;
  row-gap: 16px;
}

.address {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 8px;
  margin-bottom: 8px;
}
